<template>
  <h3>STEM模型简介</h3>
  <div class="about">    
    <p>STEM模型（Sewage Treatment Engineering Model）是施汉昌教授带领的专家团队，在积累了多年的国际模型研究和应用的基础上，针对近年国内污水处理厂工艺模拟与智能控制发展的需求，提出了结合污水处理厂实际工艺与运行特点的污水处理工程模型。该模型将国际先进的ASM系列模型（ASM1，ASM2D等）以及ASM模型之前的经典模型思想，与污水处理厂的实际运行特点结合，创造性地提出的一套能够结合污水处理厂常规指标数据进行矫正分析的模型，该模型更适合于我国污水处理厂的大数据分析和人工智能应用，能够让污水处理厂的工艺孪生和物理孪生结合，满足新时代污水处理厂运营的数字化转型需求。</p>
  </div>
</template>

<style scoped>
.about {
  text-align : left;
  text-indent: 2em
}
</style>